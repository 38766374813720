<template>
    <div class="shop" ref="shop">
        <div class="set-key" @click="showDialog">
            <span class="icon-key"></span>
        </div>
        <ul class="shop-title">
            <template v-for="(item, index) in shopTitle">
                <li class="shop-title__item" :key="index">
                    <!--                    <a href="javascript:" v-if="index === 2 || index === 3"-->
                    <!--                       :class="tabIndex === index ? 'item-hover' : ''">{{ item }}</a>-->
                    <a href="javascript:" :class="tabIndex === index ? 'item-hover' : ''"
                       @click="handleClick(index)">{{ item }}</a>
                </li>
            </template>
        </ul>
        <!--    头像    -->
        <div class="shop-content clearfix" v-if="tabIndex === 0">
            <div class="shop-content__left pull-left">
                <div class="goods-title">
                    <template v-for="(item, index) in avatarList">
                        <span :key="index" @click="typeIndex = index" :class="typeIndex === index ? 'span-hover' : ''">{{
                                item.name
                            }}</span>
                    </template>
                </div>
                <div class="goods-list" v-if="avatarList.length>0">
                    <template v-for="(b_item, b_index) in avatarList[typeIndex].list">
                        <div class="goods-list__item" @click="imgPreview(b_item, b_index)">
                            <div class="item-container">
                                <div class="item-avatar">
                                    <el-image :src="b_item.pic" alt="商品" lazy></el-image>
                                </div>
                                <div class="item-info">
                                    <span>{{ b_item.name }}</span>
                                    <!--                                    <span v-if="my_goods.indexOf(b_item.id)>0"></span>-->
                                    <!--                                    <span v-else></span>-->
                                    <div class="item-total">
                                        <img src="~@/assets/images/shop/gem1.png" alt="宝石">
                                        <span>{{ b_item.price }}</span>
                                    </div>
                                </div>
                                <img src="~@/assets/images/shop/has.png" v-if="my_goods.indexOf(b_item.id)>0" alt="已拥有"
                                     class="has-img">
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="shop-content__right pull-right">
                <div class="right-top">
                    <div class="top-view">
                        <img :src="nowItem.pic" alt="图片" v-if="nowItem">
                    </div>
                    <div class="top-info">
                        <span class="info-text" v-if="nowItem" v-html="nowItem.content"></span>
                    </div>
                </div>
                <div class="right-bottom">
                    <span class="bottom-num">数量</span>
                    <div class="computed d-flex">
                        <i class="iconfont icon-jian"></i>
                        <el-input v-model="input" placeholder="1"></el-input>
                        <i class="iconfont icon-jia"></i>
                    </div>
                    <span class="use-text">花费</span>
                    <div class="use d-flex align-items-center">
                        <img src="~@/assets/images/shop/gem1.png" alt="宝石">
                        <span v-if="nowItem">{{ nowItem.price }}</span>
                    </div>
                    <span class="has-text">拥有</span>
                    <div class="has d-flex align-items-center">
                        <img src="~@/assets/images/shop/gem1.png" alt="宝石">
                        <span>{{ user.integral }}</span>
                    </div>
                    <span class="buy-btn" @click="buy">购买</span>
                </div>
            </div>
        </div>
        <!--    头像框    -->
        <div class="shop-content clearfix" v-if="tabIndex === 1">
            <div class="shop-content__left pull-left">
                <div class="goods-title">
                    <template v-for="(item, index) in borderList">
                        <span :key="index" @click="typeIndex = index" :class="typeIndex === index ? 'span-hover' : ''">{{
                                item.name
                            }}</span>
                    </template>
                </div>
                <ul class="goods-list">
                    <template v-for="(d_item, d_index) in borderList[typeIndex].list">
                        <li class="goods-list__item" @click="imgPreview(d_item, d_index)" :key="d_index">
                            <div class="item-container">
                                <div class="item-avatar">
                                    <el-image :src="d_item.pic" alt="商品" lazy></el-image>
                                </div>
                                <div class="item-info">
                                    <span>{{ d_item.name }}</span>
                                    <div class="item-total">
                                        <img src="~@/assets/images/shop/gem1.png" alt="宝石">
                                        <span>{{ d_item.price }}</span>
                                    </div>
                                </div>
                                <img src="~@/assets/images/shop/has.png" v-if="my_goods.indexOf(d_item.id)>0" alt="已拥有"
                                     class="has-img">
                            </div>
                        </li>
                    </template>
                </ul>
            </div>
            <div class="shop-content__right pull-right">
                <div class="right-top">
                    <div class="top-view">
                        <img :src="nowItem.pic" alt="图片" v-if="nowItem">
                    </div>
                    <div class="top-info">
                        <span class="info-text" v-if="nowItem" v-html="nowItem.content"></span>
                    </div>
                </div>
                <div class="right-bottom">
                    <span class="bottom-num">数量</span>
                    <div class="computed d-flex">
                        <i class="iconfont icon-jian"></i>
                        <el-input v-model="input" placeholder="1"></el-input>
                        <i class="iconfont icon-jia"></i>
                    </div>
                    <span class="use-text">花费</span>
                    <div class="use d-flex align-items-center">
                        <img src="~@/assets/images/shop/gem1.png" alt="宝石">
                        <span v-if="nowItem">{{ nowItem.price }}</span>
                    </div>
                    <span class="has-text">拥有</span>
                    <div class="has d-flex align-items-center">
                        <img src="~@/assets/images/shop/gem1.png" alt="宝石">
                        <span>{{ user.integral }}</span>
                    </div>
                    <span class="buy-btn" @click="buy">购买</span>
                </div>
            </div>
        </div>
        <!--    礼品    -->
        <div class="shop-content clearfix" v-if="tabIndex === 2">
            <div class="shop-content__left pull-left">
                <div class="goods-title">
                    <template v-for="(item, index) in goodsList">
                        <span :key="index" @click="typeIndex = index;" :class="typeIndex === index ? 'span-hover' : ''">{{
                                item.name
                            }}</span>
                    </template>
                </div>
                <ul class="goods-list">
                    <template v-for="(item, index) in goodsList[typeIndex].list">
                        <li class="goods-list__item" @click="imgPreview(item, index)" :key="index">
                            <div class="item-container">
                                <div class="item-img">
                                    <img :src="item.pic" alt="商品">
                                    <img class="img-time" v-if="item.use_time" src="~@/assets/images/shop/time.png"
                                         alt="限时抢购">
                                </div>
                                <div class="item-info">
                                    <span class="info-name">{{ item.name }}</span>
                                    <div class="item-total">
                                        <img src="~@/assets/images/shop/gem1.png" alt="宝石">
                                        <span>{{ item.price }}</span>
                                    </div>
                                </div>
                                <!--                                <img src="~@/assets/images/shop/has.png" v-if="my_goods.indexOf(item.id)>0" alt="已拥有"-->
                                <!--                                     class="has-img">-->
                            </div>
                        </li>
                    </template>
                </ul>
            </div>
            <div class="shop-content__right pull-right">
                <div class="right-top">
                    <div class="top-container" v-if="nowItem">
                        <template v-if="nowItem.use_time">
                            <span class="info-title">
                                {{ nowItem.activity_des }}
                            </span>
                            <span>
                                <span>上架时间：</span>
                                <span>{{ nowItem.start_time }}</span>
                            </span>
                            <span>
                                <span>下架时间：</span>
                                <span>{{ nowItem.end_time }}</span>
                            </span>
                        </template>
                        <span>
                                <span>每人限购：</span>
                                <span>{{ nowItem.bounds_num }}</span>
                            </span>
                        <div class="info-detail">
                            <span>商品详情：</span>
                            <span v-html="nowItem.content"></span>
                        </div>
                        <div class="info-stock">
                            <span>剩余库存：</span>
                            <span>{{ nowItem.store_num }}</span>
                        </div>
                    </div>
                </div>
                <div class="right-bottom">
                    <span class="bottom-num">数量</span>
                    <div class="computed d-flex">
                        <i class="iconfont icon-jian" @click="reduce"></i>
                        <el-input v-model="input" placeholder="1"></el-input>
                        <i class="iconfont icon-jia" @click="increase"></i>
                    </div>
                    <span class="use-text">花费</span>
                    <div class="use d-flex align-items-center">
                        <img src="~@/assets/images/shop/gem1.png" alt="宝石">
                        <span v-if="nowItem">{{ nowItem.price * input }}</span>
                    </div>
                    <span class="has-text">拥有</span>
                    <div class="has d-flex align-items-center">
                        <img src="~@/assets/images/shop/gem1.png" alt="宝石">
                        <span>{{ user.integral }}</span>
                    </div>
                    <span class="buy-btn" @click="buy">购买</span>
                </div>
            </div>
        </div>
        <!--    已购礼品列表    -->
        <div class="shop-content clearfix" v-if="tabIndex === 3">
            <div class="shop-content__left pull-left">
                <ol class="goods-list d-flex flex-column">
                    <template v-for="(item, index) in has_goods">
                        <li :key="index" class="d-flex list-item">
                            <div class="has-time">
                                <span class="has-order">{{ index + 1 + '.' }} </span>
                                <span>{{ item.paytime }}</span>
                            </div>
                            <div class="has-detail">
                                花费 <span class="goods-price">{{ item.price }}</span>
                                购买了 <span class="goods-num"> {{ item.buy_num }} </span>个<span
                                    class="goods-text">{{ item.name }}</span>
                            </div>
                            <span class="has-status">{{ item.status === 1 ? '已兑换' : '未兑换' }}</span>
                        </li>
                    </template>
                </ol>
            </div>
        </div>
        <el-dialog class="pwd-dialog" :visible.sync="pwdVisible" width="440px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false">
            <!--     设置密码      -->
            <div class="set-pwd" v-if="step === 0">
                <span class="pwd-title">
                    设置密码
                    <i class="fa fa-close" @click="setClose"></i>
                </span>
                <div class="pwd-body d-flex flex-column align-items-center">
                    <el-input v-model="oneInput" placeholder="请输入密码" :type="pwd_type1 ? 'text' : 'password'" autocomplete="off" auto-complete="off" maxlength="6" minlength="6">
                        <i slot="suffix" :class="['iconfont', [ pwd_type1 ? 'icon-xianshikejian' : 'icon-yincangbukejian' ]]" @click="pwd_type1 = !pwd_type1"></i>
                    </el-input>
                    <el-input v-model="twoInput" placeholder="请再次输入密码" :type="pwd_type2 ? 'text' : 'password'" autocomplete="off" auto-complete="off" maxlength="6" minlength="6">
                        <i slot="suffix" :class="['iconfont', [ pwd_type2 ? 'icon-xianshikejian' : 'icon-yincangbukejian' ]]" @click="pwd_type2 = !pwd_type2"></i>
                    </el-input>
                    <el-button @click="setPwd">确定</el-button>
                    <span class="set-tip">*密码为六位数字</span>
                </div>
            </div>
            <!--     输入支付密码      -->
            <div class="set-pwd" v-if="step === 1">
                <span class="pwd-title">
                    请输入支付密码
                    <i class="fa fa-close" @click="setClose"></i>
                </span>
                <div class="pwd-body d-flex flex-column align-items-center">
                    <el-input v-model="password" placeholder="请输入密码" :type="pwd_type3 ? 'text' : 'password'" autocomplete="off" auto-complete="off" maxlength="6" minlength="6">
                        <i slot="suffix" :class="['iconfont', [ pwd_type3 ? 'icon-xianshikejian' : 'icon-yincangbukejian' ]]" @click="pwd_type3 = !pwd_type3"></i>
                    </el-input>
                    <!--                    <span class="forget-pwd">忘记密码？</span>-->
                    <el-button @click="payPwd">确定</el-button>
                </div>
            </div>
            <!--     修改密码      -->
            <div class="set-pwd" v-if="step === 2">
                <span class="pwd-title">
                    修改密码
                    <i class="fa fa-close" @click="setClose"></i>
                </span>
                <div class="pwd-body d-flex flex-column align-items-center">
                    <el-input v-model="old_pwd" placeholder="原密码" :type="pwd_type4 ? 'text' : 'password'" autocomplete="off" auto-complete="off" maxlength="6" minlength="6">
                        <i slot="suffix" :class="['iconfont', [ pwd_type4 ? 'icon-xianshikejian' : 'icon-yincangbukejian' ]]" @click="pwd_type4 = !pwd_type4"></i>
                    </el-input>
                    <el-input v-model="new_pwd" placeholder="新密码" :type="pwd_type5 ? 'text' : 'password'" autocomplete="off" auto-complete="off" maxlength="6" minlength="6">
                        <i slot="suffix" :class="['iconfont', [ pwd_type5 ? 'icon-xianshikejian' : 'icon-yincangbukejian' ]]" @click="pwd_type5 = !pwd_type5"></i>
                    </el-input>
                    <el-input v-model="con_pwd" placeholder="请确认密码" :type="pwd_type6 ? 'text' : 'password'" autocomplete="off" auto-complete="off" maxlength="6" minlength="6">
                        <i slot="suffix" :class="['iconfont', [ pwd_type6 ? 'icon-xianshikejian' : 'icon-yincangbukejian' ]]" @click="pwd_type6 = !pwd_type6"></i>
                    </el-input>
                    <el-button @click="updatePwd">确定</el-button>
                </div>
            </div>
            <!--     支付密码错误      -->
            <div class="set-pwd" v-if="step === 3">
                <div class="input-error d-flex flex-column align-items-center">
                    <span class="input-error">支付密码不正确，购买失败！！！</span>
                    <el-button>知道了</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "shopping",
    data() {
        return {
            pwd_type1: false,
            pwd_type2: false,
            pwd_type3: false,
            pwd_type4: false,
            pwd_type5: false,
            pwd_type6: false,
            step: 0,        // 0: 设置密码      1: 输入密码     2：修改密码
            old_pwd: '',
            new_pwd: '',
            con_pwd: '',
            password: '',
            oneInput: '',
            twoInput: '',
            pwdVisible: false,
            tabIndex: 0,
            typeIndex: 0,
            input: 1,
            use: '',
            has: '',
            p_id: 0,
            shopIndex: 0,
            imgSrc: '',
            infoView: '',
            avatarList: [],
            borderList: [],
            goodsList: [],
            has_goods: [],
            shopTitle: ['头像', '头像框', '礼品', '礼品购买记录'],
            nowItem: {},
            my_goods: []
        }
    },
    created() {
        this.getAvatar();
        this.getBorder();
        this.getGoods();
    },
    methods: {
        handleClick(index) {
            this.typeIndex = 0;
            this.tabIndex = index;
            if (index === 3) {
                this.getOrder();
            }

        },
        // 获取头像列表
        getAvatar() {
            this.axios
                    .post('/api/goods/cate_list', {token: this.token, pid: 1})
                    .then(res => {
                        this.avatarList = res.data.data.lists;
                        this.nowItem = this.avatarList[0].list[0];
                        this.my_goods = res.data.data.my_goods;
                        console.log(this.avatarList);
                    })
        },
        // 获取头像框列表
        getBorder() {
            this.axios
                    .post('/api/goods/cate_list', {token: this.token, pid: 2})
                    .then(res => {
                        this.borderList = res.data.data.lists;
                        console.log('', this.borderList)
                    })
        },
        // 获取礼品列表
        getGoods() {
            this.axios
                    .post('/api/goods/cate_list', {token: this.token, pid: 3})
                    .then(res => {
                        this.goodsList = res.data.data.lists;
                        console.log('礼品：', this.goodsList)
                    })
        },
        // 获取已购买的礼品列表
        getOrder() {
            this.axios
                    .post('/api/order/order_list', {token: this.token, pid: 3})
                    .then(res => {
                        this.has_goods = res.data.data.lists;
                        console.log('已购礼品：', this.has_goods);
                    })
        },
        // 预览
        imgPreview(item, index) {
            this.nowItem = item;
            // this.shopIndex = index;
            // this.imgSrc = item.pic;
            // this.infoView = item.price;
            this.p_id = item.id;
            this.input = 1;
        },
        // 购买
        buy() {
            // 判断是否设置密码：不为空，进行购买
            console.log('======', this.user.pay_password)
            if (this.user.pay_password !== "" && this.user.pay_password !== null) {
                if (this.nowItem == null) {
                    this.$confirm('请先选择商品进行购买！').then(() => {

                        // this.pwdVisible = true;
                    }).catch(err => {
                        console.log(err);
                    })
                } else {
                    if (this.input !== 0) {
                        this.$confirm('确认花费' + this.nowItem.price * this.input + '宝石购买' + this.input + '个"' + this.nowItem.name + '"吗？')
                                .then(() => {
                                    this.step = 1;
                                    this.pwdVisible = true;
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                    }
                }
            } else {
                this.$confirm('你还没有设置密码哟！请先设置密码！！！', {
                    type: 'warning'
                }).then(() => {
                    setTimeout(() => {
                        this.step = 0;
                        this.pwdVisible = true;
                    }, 800)
                }).catch((err) => {
                    console.log(err);
                })
            }


        },
        getUserInfo() {
            let than = this;
            this.loading = true;
            //let danyuan_ids = this.danyuanCheckStr;
            let params = {
                token: this.token,
            };
            this.axios
                    .post("/api/user/get_user_info", params)
                    .then((res) => {
                        let data = res.data;
                        if (data.status_code === "200") {
                            this.$store.commit("setUser", data.data);
                        } else if (res.data.status_code === "202") {
                        } else {
                            //this.$message(res.data.msg)
                        }
                    })
                    .catch((err) => {
                        //this.loading = false
                    });
        },
        increase() {
            this.input++;
            if (this.input > this.nowItem.store_num || this.input > this.nowItem.bounds_num) {
                this.input = this.nowItem.bounds_num;
            }
        },
        reduce() {
            this.input--;
            if (this.input < 0) {
                this.input = 0;
            }
        },
        setClose() {
            this.pwdVisible = false;
            this.oneInput = '';
            this.twoInput = '';
            this.old_pwd = '';
            this.new_pwd = '';
            this.con_pwd = '';
            this.password = '';
        },
        showDialog() {
            if (this.user.pay_password !== '' && this.user.pay_password != null) {
                this.step = 2;
                this.pwdVisible = true;
            } else {
                this.step = 0;
                this.pwdVisible = true;
            }
        },
        setPwd() {
            console.log('====', this.user.pay_password);
            if (this.twoInput === this.oneInput) {
                this.axios.post('/api/user/set_pass_word', {token: this.token, pwd: this.oneInput}).then(res => {
                    console.log(res.data)
                    if (this.user.pay_password !== '') {
                        this.$confirm(res.data.msg).then(() => {
                            setTimeout(() => {
                                this.pwdVisible = false;
                            }, 500)

                        }).catch((err) => {
                            console.log(err);
                        })
                    } else {
                        this.$confirm(res.data.msg, {
                            type: 'success'
                        }).then(() => {
                            this.pwdVisible = false;
                            this.getUserInfo()
                        })
                    }
                })
            } else {
                this.$confirm('请输入相同的密码', {
                    type: 'warning'
                }).then(() => {
                }).catch(err => {
                    cosnole.log(err)
                });
                return
            }
        },
        payPwd() {
            this.axios
                    .post('/api/order/pay', {token: this.token, g_id: this.p_id, g_num: 1, pay_pwd: this.password})
                    .then(res => {
                        if (res.data.status_code === "200") {
                            this.$message({
                                showClose: true,
                                message: res.data.msg,
                                type: 'success',
                                offset: 400
                            });
                            this.user.integral = this.user.integral - this.nowItem.price * this.input;
                            this.my_goods.push(this.p_id);
                            setTimeout(()=>{
                                this.pwdVisible = false;
                            }, 1000);
                            this.getUserInfo();
                        } else {
                            this.$message({
                                showClose: true,
                                message: res.data.msg,
                                type: 'warning',
                                offset: 400
                            })
                            setTimeout(()=>{
                                this.pwdVisible = false;
                            }, 1000);
                        }
                    })
        },
        updatePwd() {
            if (this.con_pwd !== this.new_pwd) {
                this.$confirm('两次输入的密码不一致，请重新输入', {
                    type: 'warning'
                }).then(() => {
                    return
                }).catch((err) => {
                    console.log(err);
                })
            } else {
                this.axios.post('/api/user/update_pay_pass_word', {
                    token: this.token,
                    old_pwd: this.old_pwd,
                    pwd: this.new_pwd
                }).then(res => {
                    this.$message({
                        showClose: true,
                        message: res.data.msg,
                        type: 'warning',
                        offset: 400
                    });
                })
            }
        }
    },
    computed: {
        ...mapState({
            token: (state) => state.user.token,
            user: (state) => state.user.user,
        }),
    },
}
</script>


<style lang="scss">

@mixin common {
    width: 165px;
    height: 38px;
    font-size: 16px;
    background: url("~@/assets/images/shop/input.png") no-repeat;
    background-size: 100% 100%;
}

@mixin lr {
    background-color: #e2cbab;
}

@mixin flexible($justify: center) {
    display: flex;
    justify-content: $justify;
    align-items: center;
}

.shop {
    position: relative;
    display: flex;
    flex-direction: column;
    font-family: '微软雅黑', serif;

    .set-key {
        position: absolute;
        right: 20px;
        cursor: pointer;

        .icon-key {
            display: inline-block;
            width: 35px;
            height: 35px;
            background: url("~@/assets/images/shop/key.svg") no-repeat;
            background-size: 100% 100%;

            &:hover {
                background: url("~@/assets/images/shop/key_active.svg") no-repeat;
                background-size: 100% 100%;
            }

        }
    }

    &-title {
        display: flex;
        margin-bottom: 20px;
        padding: 0;

        &__item {
            margin-right: 50px;
        }

        a {
            display: inline-block;
            width: 135px;
            height: 35px;
            line-height: 35px;
            color: #72482a;
            font-size: 16px;
            text-align: center;
            letter-spacing: 2px;
            background: url("~@/assets/images/shop/item.png") no-repeat;
            background-size: 100% 100%;

            &.item-hover {
                background: url("~@/assets/images/shop/item_hover.png");
                background-size: 100% 100%;
            }
        }
    }

    &-content {
        &__left {
            width: 74%;
            height: 452px;
            @include lr;

            .goods-title {
                @include flexible(space-around);
                height: 40px;
                line-height: 40px;
                border-bottom: 1px solid #C89E7C;

                span {
                    display: inline-block;
                    height: 100%;
                    color: #72482a;
                    cursor: pointer;
                }

                .span-hover {
                    border-bottom: 3px solid #72482a;
                }
            }

            .goods-list {
                overflow: auto;
                display: flex;
                flex-wrap: wrap;
                gap: 15px 8px;
                padding: 15px 15px;
                height: 400px;

                &::-webkit-scrollbar {
                    width: 8px;
                    height: 345px;
                    border-radius: 4px;
                }

                &::-webkit-scrollbar-track {
                    border-radius: 4px;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                    background-color: #ede6d1;
                }

                .has-status {
                    margin-left: auto;
                }

                .has-order {
                    margin-right: 10px;
                    font-size: 20px;
                    font-style: italic;
                }

                .goods-price, .goods-num, .goods-text, .has-status {
                    color: red;
                    font-size: 18px;
                }

                .list-item {
                    width: 100%;
                }

                &__item {
                    width: 24%;
                    height: 180px;
                    cursor: pointer;

                    .item-container {
                        position: relative;
                        border: 1px solid #72482a;
                        border-radius: 8px;
                        transition: all .3s ease;

                        &:hover {
                            box-shadow: 0 5px 10px #7a7a7a;
                            transform: translateY(-5px);
                        }
                    }

                    .item-img {
                        @include flexible;
                        position: relative;
                        height: 120px;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 8px;
                        }

                        .img-time {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 60px;
                            height: 60px
                        }
                    }

                    .item-avatar {
                        @include flexible;
                        height: 120px;

                        img, .el-image {
                            width: 80px;
                            height: 80px;
                            transition: all .2s;

                            &:hover {
                                width: 100px;
                                height: 100px;
                            }
                        }
                    }

                    .item-info {
                        @include flexible(space-around);
                        flex-direction: column;
                        padding: 5px 0;
                        height: 60px;
                        background-color: #ede6d1;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;

                        span {
                            color: #72482a;
                            //font-size: 14px;
                        }

                        .info-name {
                            width: 140px;
                            text-align: center;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        .item-total {
                            img {
                                margin-right: 8px;
                                vertical-align: -8px;
                                width: 22px;
                                height: 30px;
                            }

                            span {
                                font-size: 17px;
                            }
                        }
                    }

                    .has-img {
                        position: absolute;
                        right: -18px;
                        bottom: -20px;
                        width: 80px;
                        height: 80px;
                    }
                }
            }

            ol.goods-list {
                row-gap: 8px;
                height: 452px;
                flex-wrap: nowrap;

                li {
                    padding: 0 20px;
                    height: 36px;
                    line-height: 36px;
                    background-color: #ECD9BD;

                    &:nth-child(even) {
                        background-color: #F2D8B2;
                    }

                    .has-time {
                        margin-right: 80px;
                        font-size: 16px;

                        & > span:nth-child(2) {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        &__right {
            width: 24%;
            height: 452px;

            .right-top {
                margin-bottom: 10px;
                height: 265px;
                @include lr;

                .top-view {
                    @include flexible();
                    width: 100%;
                    height: 200px;

                    img {
                        width: 150px;
                        height: 150px;
                    }
                }

                .top-info {
                    @include flexible();
                    padding: 6px;
                    height: 65px;
                    //background-color: #ede6d1;
                    span {
                        color: #72482a;
                        font-size: 24px;
                    }

                    .info-text {
                        font-size: 14px;
                        text-indent: 2em;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                    }
                }

                .top-container {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    padding: 5px;
                    height: 265px;

                    .info-title {
                        align-self: center;
                        color: red;
                    }

                    .info-detail {
                        display: flex;
                        flex-direction: column;
                        row-gap: 8px;

                        & > span:first-child {
                            color: red;
                        }

                        & > span:last-child {
                            text-indent: 2em;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 6;
                        }
                    }

                    .info-stock {
                        position: absolute;
                        bottom: 10px;
                        right: 10px;

                        & > span:first-child {
                            color: red;
                        }
                    }
                }
            }

            .right-bottom {
                display: grid;
                grid-template:
                    "num total total total total" 1fr
                    "use-text use use use use" 1fr
                    "has-text has has has has" 1fr
                    "btn btn btn btn btn" 1fr
                    / 1fr 1fr 1fr 1fr 1fr;
                justify-items: center;
                align-items: center;
                row-gap: 2px;

                .el-input {
                    height: 32px;

                    .el-input__inner {
                        height: 32px;
                        color: #634a2e;
                        font-size: 16px;
                        text-align: center;
                        background-color: #ede6d1 !important;

                        &::placeholder {
                            color: #999;
                        }
                    }

                }

                .bottom-num {
                    grid-area: num;
                }

                .computed {
                    align-items: center;
                    grid-area: total;

                    i {
                        color: #ecd5b3;
                        font-size: 30px;
                        background-color: #634a2e;
                        border: 1px solid #dec6a6;
                        border-radius: 50%;
                        cursor: pointer;

                        &.icon-jian {
                            margin-right: 8px;
                        }

                        &.icon-jia {
                            margin-left: 8px;
                        }
                    }

                }

                .use-text {
                    grid-area: use-text;
                }

                .use {
                    grid-area: use;
                    @include common;
                    text-align: center;

                    span {
                        margin: 0 auto;
                    }

                    img {
                        margin-left: 5px;
                        width: 22px;
                        height: 30px;
                    }

                }

                .has-text {
                    grid-area: has-text;
                }

                .has {
                    grid-area: has;
                    @include common;
                    text-align: center;

                    span {
                        margin: 0 auto;
                    }

                    img {
                        margin-left: 5px;
                        width: 22px;
                        height: 30px;
                    }
                }

                .buy-btn {
                    grid-area: btn;
                    display: inline-block;
                    margin-top: 6px;
                    width: 100%;
                    height: 37px;
                    line-height: 37px;
                    text-align: center;
                    background-image: url("~@/assets/images/shop/item_hover.png");
                    background-size: 100% 100%;
                    cursor: pointer;
                }
            }
        }
    }
}

.pwd-dialog {
    .el-dialog {
        margin-top: 30vh !important;
        border-radius: 8px;

        &__header {
            padding: 0;
            height: 0;
            border: 0;
        }

        &__body {
            height: 100%;
            background-color: #F7EEDB;
            border-radius: 8px;

            .set-pwd {
                .pwd-title {
                    position: relative;
                    display: inline-block;
                    width: 100%;
                    height: 60px;
                    line-height: 60px;
                    font-size: 22px;
                    text-align: center;
                    background-color: #F0CE63;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;

                    i {
                        position: absolute;
                        top: 18px;
                        right: 28px;
                        cursor: pointer;
                    }
                }

                .pwd-body {
                    padding-bottom: 50px;

                    .el-input {
                        width: 370px;

                        &__inner {
                            height: 50px;
                            border: 1px solid #72492E;
                        }

                        &:first-child {
                            margin-top: 40px;
                        }

                        &:nth-child(2) {
                            margin-top: 20px;
                        }

                        &:nth-of-type(3) {
                            margin-top: 20px;
                        }

                        &__suffix {
                            line-height: 50px;

                            &-inner {
                                i {
                                    font-size: 24px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    .el-button {
                        margin-top: 20px;
                        padding: 16px 20px;
                        width: 365px;
                        font-size: 16px;
                        letter-spacing: 2px;
                        background-color: #F0CE63;
                        border: 0;
                    }

                    .forget-pwd {
                        display: inline-block;
                        margin-top: 10px;
                        width: 365px;
                        text-align: right;
                    }

                    .set-tip {
                        display: inline-block;
                        margin-top: 12px;
                        width: 365px;
                        font-size: 12px;
                    }
                }

                .input-error {
                    padding: 15px 20px;

                    .el-button {
                        margin-top: 20px;
                        width: 365px;
                        font-size: 15px;
                        letter-spacing: 2px;
                        background-color: #F0CE63;
                        border: 0;
                    }
                }
            }
        }
    }
}


</style>